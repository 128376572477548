import * as React from "react";
import { withRouter } from "react-router";
import { GET_ARTICLE, GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Component } from "react";
import animations from "../Chapitre/animations";
import Content from "./Content";
import { ScrollContext } from "../../../Scroll/Scroll";

class ContentArticle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display: false,
            ref: null,
            listenScroll: true,
        };
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    componentDidMount() {
        if(this.ref){
            //animations.ArticleEnter(this.ref);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.match.params.slug !== this.props.match.params.slug || !prevState.listenScroll){
            this.resetListenScroll();
        }
    }

    componentWillUnmount() {
        if(this.ref) {
            //animations.ArticleLeave(this.ref);
        }
    }

    findArticlesToChapitre(articles, numero) {
        if(!articles) {
            this.props.history.push("/"); return null;
        }
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    getArticleWithQuery(articles, numero) {

        if(!articles) {
            this.props.history.push("/"); return null;
        }

        let article = articles.filter((article) => article.slug === this.props.match.params.slug)[0];

        if(!article) {
            return null;
        }

        return article;

    }

    articleIsRead = (user, article) => {
        if(user.articles) {
            if(!article) return null;
            return user.articles.some((articleObj) => {
                return (articleObj._id.split("_")[0] === article._id.split("_")[0] && articleObj.readed);
                //return (articleObj._id === article._id && articleObj.readed);
            });
        }
    };

    blockListenScroll = () => {
        this.setState({
            listenScroll: false,
        })
    };

    resetListenScroll = () => {
        this.setState({
            listenScroll: true,
        })
    };


    render() {

        if(!this.props.match.params.slug) return null;

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(error) return null;
                    if(loading) return null;

                    let currentNumero = data.currentNumero;
                    let articles = currentNumero.articles;
                    let articles_chapitre = this.findArticlesToChapitre(articles, currentNumero);
                    let article = this.getArticleWithQuery(articles, currentNumero);

                    if(!article) return null;

                    return(
                        <Query query={ GET_ARTICLE }
                               variables={{ magazine_slug: this.context.magazine.slug, article_id: article._id }}
                               fetchPolicy="cache-first">
                            {({ loading, error, data, networkStatus }) => {

                                if(loading) return null;

                                if(data.getArticle)
                                    if(articles_chapitre)
                                        return (<div ref={ this.setReference }>
                                            <Content
                                                match={ this.props.match }
                                                display={ this.state.display }
                                                toggleMenu={ this.props.toggleMenu }
                                                articles={ articles_chapitre }
                                                type="article"
                                                magazine={ this.context.magazine }
                                                article={ data.getArticle }
                                                articleDetail={ article }
                                                allArticles={ articles }
                                                categories={ currentNumero.categories }
                                                numero={ currentNumero }
                                            />
                                            <ScrollContext.Consumer>
                                                {({ changeScrollWidth, scrollWidth, resetScrollWidth }) => (
                                                    <ArticleContextScroll
                                                        scrollWidth={ scrollWidth }
                                                        resetScrollWidth={ resetScrollWidth }
                                                        user={ this.props.user }
                                                        fixTimelineBar={ this.props.fixTimelineBar }
                                                        fixTimelineBarValue={ this.props.fixTimelineBarValue }
                                                        unfixTimelineBar={ this.props.unfixTimelineBar }
                                                        blockListenScroll={ this.blockListenScroll }
                                                        resetListenScroll={ this.resetListenScroll }
                                                        currentNumero={ currentNumero }
                                                        setArticleReaded={ this.props.setArticleReaded }
                                                        articleIsRead={ this.articleIsRead }
                                                        article={ article }
                                                        listenScroll={ this.state.listenScroll }
                                                    />
                                                )}
                                            </ScrollContext.Consumer>
                                        </div>);
                                    else return "Pas d'articles dans ce chapitre";

                                return "Article network status";

                            }}
                        </Query>
                    );

                }}
            </Query>
        )
    }
}

ContentArticle.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        user: state.user,
        fixTimelineBarValue: state.scrolled.fixTimelineBar
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        renderArticle: () => {
            dispatch({
                type: 'RENDER_ARTICLE'
            });
        },
        setArticleReaded: (article, numero) => {
            dispatch({
                type: 'USER_SET_ARTICLE_READ',
                article,
                typeData: "article",
                numero
            });
        },
        fixTimelineBar: () => {
            dispatch({
                type: 'FIX_TIMELINE_BAR',
            });
        },
        unfixTimelineBar: () => {
            dispatch({
                type: 'UNFIX_TIMELINE_BAR',
            });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ContentArticle)));

export class ArticleContextScroll extends Component {
    constructor(props){
        super(props);
    }

    componentDidUpdate() {
      if(this.props.scrollWidth > 85){
          if(!this.props.fixTimelineBarValue)
          this.props.fixTimelineBar()
      } else {
          if(this.props.fixTimelineBarValue)
          this.props.unfixTimelineBar()
      }

      if(this.props.scrollWidth > 85 && this.props.listenScroll){
          if(!this.props.articleIsRead(this.props.user, this.props.article)) {
            this.props.blockListenScroll();
            this.props.resetScrollWidth();
            this.props.setArticleReaded(this.props.article, this.props.currentNumero);
          }
      }
    }

    render() {
        return null;
    }
}
