import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "./Header";
import MainView from "./views/MainView";
import Menu from "../../Menu/Menu";

import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuNumero from "../../MenuNumero/MenuNumero";
import Search from "../../Search/Search";
// import Search from "../../Search/Search";
// import { disableBodyScroll } from "body-scroll-lock";

class Main extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
        }
    }

    isPreview = () => {
        return this.props.location.search;
    };

    render() {
        const { match, previewLoading } = this.props;

        return (
            <div className="main" data-text="true">
                <Header match={ match } toggleMenu={ this.props.toggleMenu } menuShow={ this.props.menuShow }/>
                {!this.props.menuShow && !this.props.menuNumeroActive && !this.props.search &&
                    <MainView match={ match } show={ this.props.menuShow }
                              toggleMenu={ this.props.toggleMenu }
                              isPreview={ this.isPreview }
                              previewLoading={ previewLoading }
                    />
                }
                <Menu show={ this.props.menuShow } toggleMenu={ this.props.toggleMenu }/>
                <MenuNumero toggleMenu={ this.props.toggleMenu } />
                {typeof window !== "undefined" &&
                    <Search toggleMenu={ this.props.toggleMenu } />
                }
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        previewLoading: state.preview.previewLoading,
        menuNumeroActive: state.menuNumero.active,
        search: state.search.isSearching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Main)));

