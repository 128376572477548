import React, { Component, PureComponent } from 'react';
import AnimBackground2 from "./AnimBackground2";
import animations from "../animations";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { NumeroMenu } from "../../Numero/components/views/NumeroMenu";

import "../style/menu.scss";
import classNames from "classnames";
import Loadable from "react-loadable";

const { createPortal, render } = ReactDOM;

let BodyScrollModule = null;

function truncate(input, length) {
    if (input.length > length)
        return input.substring(0, length) + '...';
    else
        return input;
}

const Loading = () => null;

const SvgCircleOuter = Loadable({
    loader: () => import('./SvgCircleOuter'),
    loading: Loading
});

function waitAnimationEnd(fn, duration)  {
    setTimeout(() => {
        fn();
    }, duration);
}

class ChapitreMenu extends Component {

    constructor(props){
        super(props);

        this.ref = "";

        if(this.props.articles.length > 0) {
            this.state = {
                backgroundImage: (this.props.articles[0].wallpaper.formats.menu) ?
                    this.props.articles[0].wallpaper.formats.menu : this.props.logo.horizontal_blanc,
                backgroundImageHover: (this.props.articles[0].wallpaper.formats.menu) ?
                    this.props.articles[0].wallpaper.formats.menu : this.props.logo.horizontal_blanc,
                noImg: !this.props.articles[0].wallpaper.formats.menu,
                open: false,
            };
        }
        else if(this.props.category && this.props.category.wallpaper && this.props.category.wallpaper.formats) {
            this.state = {
                backgroundImage: (this.props.category.wallpaper.formats.menu) ?
                    this.props.category.wallpaper.formats.menu : this.props.logo.horizontal_blanc,
                backgroundImageHover: (this.props.category.wallpaper.formats.menu) ?
                    this.props.category.wallpaper.formats.menu : this.props.logo.horizontal_blanc,
                noImg: !this.props.category.wallpaper.formats.menu,
                open: false,
            };
        }
        else {
            this.state = {
                backgroundImage: "",
                backgroundImageHover: "",
                noImg: true,
                open: false
            };
        }
    }

    changeBackgroundHover = (article) => {
        this.setState({
            backgroundImageHover: (article.wallpaper.formats.menu) ?
                article.wallpaper.formats.menu : this.props.logo.horizontal_blanc,
            noImg: !article.wallpaper.formats.menu
        });
    };

    changeBackgroundOut = (article) => {
        this.setState({
            backgroundImageHover: this.state.backgroundImage,
            noImg: !article.wallpaper.formats.menu
        });
    };


    isLastRead = (user, articles) => {

        if(user.lastArticlesRead) {
            return articles.some((articleObj) => {
                return user.lastArticlesRead.find((obj) => {
                    return (articleObj._id.split("_")[0] === obj._id.split("_")[0]);
                });
            });

        } return false;
    };

    articlesAlreadyReadInCategory = (user, categoryArticles) => {
        if(user.articles) {
            return categoryArticles.some((article) => {
                return user.articles.some((articleObj) => {
                    return (articleObj._id === article._id && articleObj.readed);
                });
            });
        } return false;
    };

    isRead = (user, article) => {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id.split("_")[0] === article._id.split("_")[0] && articleObj.readed);
            });
        } return false;
    };

    getLastReadByDate = (numero) => {

        let lastArticlesRead = this.props.user.lastArticlesRead.filter((obj) => {
            return (numero._id.split("_")[0] === obj.numero._id.split("_")[0]);
        });

        if(lastArticlesRead){
            return lastArticlesRead.sort((a, b) => {
                return (b.date - a.date)
            })[0];
        } return false;
    };

    isLastReadByDate = (user, articles, numero) => {
        if(user.lastArticlesRead) {

            let articlesReaded = articles.filter((articleObj) => {
                return user.lastArticlesRead.filter((obj) => {
                    return (articleObj._id.split("_")[0] === obj._id.split("_")[0]);
                });
            });

            let lastReadArticle = this.getLastReadByDate(numero);

            if(lastReadArticle) {
                if (articlesReaded) {
                    if (Array.isArray(articlesReaded)) {
                        return articlesReaded.find((obj) => {
                            return (lastReadArticle._id.split("_")[0] === obj._id.split("_")[0]);
                        });
                    } else {
                        return articlesReaded._id.split("_")[0] === lastReadArticle._id.split("_")[0]
                    }
                }
            } else {
                return false;
            }

        } return false;
    };

    nbrReadedInCat = (user, articles) => { let self = this;
        return articles.reduce((acc, val) => {
            return self.isRead(user, val) === false ? acc : acc + 1;
        }, 0);
    };

    calculCircle = (articles, user) => {

        let self = this;

        let min = 250; // no read
        let max = 119; // all read

        if(articles) {

            let nbrArticles = articles.length;

            let nbrReaded = articles.reduce((acc, val) => {
                return self.isRead(user, val) === false ? acc : acc + 1;
            }, 0);

            let diff = nbrReaded / nbrArticles;
            let differentiel = min - max;

            return min - (diff * differentiel);
        }

        return min;

    };

    goToArticle = (e, link) => {
        e.preventDefault();
        this.props.setMenuInner(false);
        this.props.toggleMenu(false);
        this.props.history.push(link);
    };

    goTo = (category, e) => {
        e.preventDefault();

        if(this.props.articles.length === 0){
            this.props.setMenuInner(false, category);
            this.props.toggleMenu(true);
            this.props.history.push(`/${this.props.numero.slug}/${category.slug}`);
        } else {
            this.props.setMenuInner(false);
            this.props.setMenuInner(true, category);
        }
    };

    isCategoryReaded = (category) => {
        return this.props.user.articles
            .find((article) => article._id.split("_")[0] === category._id.split("_")[0]
                && article.readed
                && article.type === "chapitre"
        )
    };

    getReadedArticlesCategory = (articles) => {

        let count = 0;

        articles.forEach(article => {
            if(this.props.user.articles.find(
                (articleObj) => articleObj._id === article._id
                                && articleObj.readed
                                && articleObj.type === "article"
                )){
                count++;
            }
        });

        return count;
    };

    render() { let that = this; const { articles, category, fonts, numero, toggleMenu, user } = this.props;

        if(this.props.articles)
        {

            let status = "non_lu";
            let nbrArticles = (articles.length === 0) ? 1 : articles.length;
            let nbrArticlesReaded = that.getReadedArticlesCategory(articles);
            let lastRead, alreadyRead, isLastReadCategory;

            if(category.timeForRead){
                lastRead = that.isLastRead(user, [category]);
                alreadyRead = that.articlesAlreadyReadInCategory(user, [category]);
                isLastReadCategory = this.isLastReadByDate(user, [category], numero);
            } else {
                lastRead = that.isLastRead(user, articles);
                alreadyRead = that.articlesAlreadyReadInCategory(user, articles);
                isLastReadCategory = this.isLastReadByDate(user, articles, numero);
            }

            if(category.timeForRead){

                if(that.isCategoryReaded(category)){
                    nbrArticlesReaded = 1;
                    status = "lu";
                } else if(lastRead) {
                    status = "en_cours"
                } else {
                    status = "non_lu";
                }
            } else {
                if(nbrArticlesReaded === nbrArticles){
                    status = "lu";
                } else if(nbrArticlesReaded > 0 || lastRead) {
                    status = "en_cours"
                } else {
                    status = "non_lu";
                }
            }

            let couleurRead = "#c3c3c3";

            if(status === "lu"){
                couleurRead = "#08BADF";
            }

            if(status === "en_cours"){
                couleurRead = "#5C2483";
            }

            const classesLi = classNames({
                'chapitre': true,
                'no-img': this.state.noImg,
                'non_lu': status === 'non_lu',
                'lu': status === 'lu',
                'en_cours': status === 'en_cours',
            });

            const classes = classNames({
                'no-read-yet': (that.nbrReadedInCat(user, articles) < articles.length),
                'readed': (that.nbrReadedInCat(user, articles) === articles.length),
                'chapitre-title': true,
                'no-img': this.state.noImg
            });

            return(
                <li key={ category._id } className={ classesLi }>
                    <Link to={(articles.length === 0) ? `/${numero.slug}/${category.slug}` : "#"}
                          onClick={(e) => this.goTo(category, e) }>
                        <div className="inner">
                            <div className="categories" style={ fonts.family1 }>
                                <div className="chapitre-wallpaper"
                                     style={ { backgroundImage : `url(${ this.state.backgroundImageHover })` } }/>
                                <div className="timeline-circle-category" style={ fonts.family2 }>
                                    { isLastReadCategory &&
                                        <span className="reading-outer" style={{ backgroundColor: couleurRead }}>
                                            <span className="reading">En cours</span>
                                        </span>
                                    }
                                    <SvgCircleOuter
                                        calculCircle={ that.calculCircle }
                                        category={ category }
                                        user={ user }
                                        status={ status }
                                        articles={ articles }
                                        isLastRead={ lastRead }
                                        alreadyRead={ alreadyRead }
                                    />
                                </div>
                                <div className={ "infosCategory " + status }>
                                    <div className="head">
                                        {articles.length === 0 && <span className="type">Article</span>}
                                        {articles.length !== 0 && <span className="type">Chapitre</span>}
                                        <span className="articlesLus">
                                            <span>{ nbrArticlesReaded }/{ nbrArticles }</span> articles lus
                                        </span>
                                    </div>
                                    <span className="title">
                                        { category.title }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <ArticlesListingMenu {...this.props} classes={ classes }
                                         nbrArticlesReaded={ nbrArticlesReaded }
                                         setRefListing={ this.props.setRefListing }
                                         toggleMenuInner={ this.props.toggleMenuInner }
                                         setMenuInner={ this.props.setMenuInner }
                                         goToArticle={ this.goToArticle }
                                         articlesAlreadyReadInCategory={ this.articlesAlreadyReadInCategory }
                                         openCategory={ this.props.menuInner.category }
                                         nbrArticles={ nbrArticles }
                                         category={ category }
                                         calculCircle={ this.calculCircle }
                                         lastRead={ lastRead }
                                         isLastRead={ this.isLastRead }
                                         alreadyRead={ alreadyRead }
                                         toggleOpen={ this.props.openToggle } open={ this.props.menuInner.show }
                                         isRead={ this.isRead } changeBackgroundOut={ this.changeBackgroundOut }
                                         changeBackgroundHover={ this.changeBackgroundHover }
                                         nbrReadedInCat={ this.nbrReadedInCat } />
                </li>
            )
        }

    }


}

class ArticlesListingMenu extends PureComponent {

    render() {

        return(
            <AbsoluteMenuInner open={ this.props.open } toggleOpen={ this.props.toggleMenuInner }
                               category={ this.props.category } template="cci" openCategory={ this.props.menuInner.category }>
                <div className="closeListingMenu" onClick={() => this.props.setMenuInner(false) } />
                <div className="articlesListingMenu" ref={(ref) => this.props.setRefListing(ref) }>
                    <div className="head">
                        <div className="back-btn" onClick={() => this.props.toggleMenuInner(false) }>
                            <i className="ico-cci-arrow-left-rounded"/>Revenir aux chapitres
                        </div>
                        <span className="articlesLus">
                            <span>{ this.props.nbrArticlesReaded }/{ this.props.nbrArticles }</span> articles lus
                        </span>
                    </div>
                    <div className="categoryHead">
                        <div className="timeline-circle-category" style={ this.props.fonts.family2 }>
                            <SvgCircleOuter
                                calculCircle={ this.props.calculCircle }
                                category={ this.props.category }
                                user={ this.props.user }
                                articles={ this.props.articles }
                                isLastRead={ this.props.lastRead }
                                alreadyRead={ this.props.alreadyRead }
                            />
                        </div>
                        <span className={ this.props.classes }>{ this.props.category.title}</span>
                    </div>
                    <ArticlesListing {...this.props} isRead={ this.props.isRead }
                         goToArticle={ this.props.goToArticle }
                         isLastRead={ this.props.isLastRead }
                         articlesAlreadyReadInCategory={ this.props.articlesAlreadyReadInCategory}
                         changeBackgroundOut={ this.props.changeBackgroundOut }
                         changeBackgroundHover={ this.props.changeBackgroundHover }
                         nbrReadedInCat={ this.props.nbrReadedInCat }
                    />
                </div>
            </AbsoluteMenuInner>
        )
    }

}

const ArticlesListing = ({ articles, user, fonts, numero, category, toggleMenu, isRead, changeBackgroundHover, changeBackgroundOut, nbrReadedInCat, goToArticle, articlesAlreadyReadInCategory, isLastRead }) => {

    let findSubcategory = articles.find((article) => {
        return article.custom.sous_chapitre
    });

    let haveSubcategory = (article) => {
        return article.custom.sous_chapitre
    };

    let sortFn = (article1, article2) => {
        if (article1.custom.sous_chapitre < article2.custom.sous_chapitre)
            return -1;
        if (article1.custom.sous_chapitre > article2.custom.sous_chapitre)
            return 1;
        return 0;
    };

    let sortArticles = (articles) =>
        articles.sort((a, b) => { return (a.positionChapitre - b.positionChapitre) });

    let renderArticles = (articlesArr) => {
        return articlesArr.map(article => {

            let isReadArticle = isRead(user, article);
            let isInRead = isLastRead(user, [article]);

            const classes = classNames({
                'unread': (!isReadArticle && !isInRead),
            });

            const iconClasses = classNames({
                'ico-cci-lu': isReadArticle,
                'ico-cci-non_lu': !isReadArticle
            });

            return (
                <li className={ classes } key={article._id} style={fonts.family3}>
                    <Link to={`/${numero.slug}/${category.slug}/${article.slug}`}
                          onClick={(e) => goToArticle(e, `/${numero.slug}/${category.slug}/${article.slug}`) } key={article._id} className={classes}
                    >
                        <span>{article.title}</span>
                    </Link>
                </li>
            );

        });
    };

    if(!findSubcategory){
        return renderArticles(articles);
    } else {
        let sort = articles.sort(sortFn);
        let sousChapitresArr = [];
        let isBefore = true;
        let articlesNoSubcategories = {
            before: [],
            after: []
        };

        articles.forEach(function (article) {

            if(article.custom.sous_chapitre) {
                isBefore = false;
                if (sousChapitresArr.length < 1) {
                    sousChapitresArr.push(article.custom.sous_chapitre);
                }

                if (!sousChapitresArr.find((sous_chapitre) => (sous_chapitre.slug === article.custom.sous_chapitre.slug))) {
                    sousChapitresArr.push(article.custom.sous_chapitre)
                }
            } else {
                if(isBefore) {
                    articlesNoSubcategories.before.push(article);
                } else {
                    articlesNoSubcategories.after.push(article);
                }
            }

        });

        let arrayReturn = [];

        if(articlesNoSubcategories.before.length > 0){
            arrayReturn.push(
                <div key={ 2 } className="articles-before-list">
                    {renderArticles(articlesNoSubcategories.before)}
                </div>
            )
        }

        if(sousChapitresArr.length > 0){
            arrayReturn.push(
                <div className="subcategories" key={ 1 }>
                    {sousChapitresArr.map((sous_chapitre) => {
                        let articlesSub = articles.filter((article) =>
                        {
                            return (article.custom.sous_chapitre && article.custom.sous_chapitre.slug === sous_chapitre.slug)
                        });


                        const classesSubcategory = classNames({
                            'unread': nbrReadedInCat(user, articlesSub) === 0,
                            'readed': ((nbrReadedInCat(user, articlesSub) === articles.length) || true),
                            'subcategory-title': true
                        });

                        let nbrReaded = nbrReadedInCat(user, articlesSub);

                        return(
                            <div className="subcategory" key={ sous_chapitre.id }>
                                <div className={ classesSubcategory }>
                                    <span className="title">{ sous_chapitre.title }</span>
                                    <span className="reads">
                                    <span className="number">{ nbrReaded }</span> / {articlesSub.length}
                                </span>
                                </div>
                                {articlesSub && renderArticles(articlesSub)}
                            </div>
                        )
                    })}
                </div>
            )
        }

        if(articlesNoSubcategories.after.length > 0){
            arrayReturn.push(
                <div key={ 3 } className="articles-after-list">
                    {renderArticles(articlesNoSubcategories.after)}
                </div>
            )
        }

        return arrayReturn;
    }

};

class MenuView extends Component {

    constructor(props){
        super(props);

        this.ref = "";
        this.footerRef = "";

        this.state = {
            render: false,
            animBackground: false,
            numeroMenu: null,
            bodyScrollModuleLoaded: false,
            openCategory: null,
            open: false
        };

    }

    /*static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.show !== prevState.show)
            return {
                show: nextProps.show
            };

        return null;
    }*/

    componentWillUnmount() { let self = this;
        animations.setOnEnter(this.ref);
        if(self.state.bodyScrollModuleLoaded) {
            BodyScrollModule.clearAllBodyScrollLocks();
        }
        // animations.footerMenuLeave(this.footerRef);
    }

    animationMenu(show) {

        if(show){

            setTimeout(() => {
                animations.setOnEnter(this.ref);
                animations.onEnter(this.ref);
                animations.footerMenuEnter(this.footerRef);
            }, 10);

        } else {

            this.setState({
                animBackground: !this.state.animBackground,
            });

            animations.setOnEnter(this.ref);
            animations.footerMenuLeave(this.footerRef);

            /*setTimeout(() => {
                this.setState({
                    render: false,
                });
            }, 200);*/

        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    setFooterReference = (ref) => {
        this.footerRef = ref;
    };

    shouldComponentUpdate(prevProps, prevState) {
        return (prevProps.show !== this.props.show || prevProps.menuInner.show !== this.props.menuInner.show)
    }

    componentDidUpdate(prevProps, prevState) { let self = this;

        if(prevState.animBackgroundAnimation !== true) {

            if(self.props.menuInner.show) {
                if(self.state.bodyScrollModuleLoaded) {
                    BodyScrollModule.disableBodyScroll(this.menuInterface);
                }
            } else {
                if(self.state.bodyScrollModuleLoaded) {
                    BodyScrollModule.clearAllBodyScrollLocks();
                    //BodyScrollModule.enableBodyScroll(this.menuInterface);
                }
            }

            if (prevProps.show !== this.props.show) {
                if(typeof window.scrollTo !== "undefined"){
                    window.scrollTo(0,0);
                }

                /*setTimeout(function() {
                    if(document.querySelector(".menu.toggled")) {
                        if (typeof document.querySelector(".menu.toggled").scrollTo !== "undefined") {
                            document.querySelector(".menu.toggled").scrollTo(0, 0);
                            self.menuInterface.scrollTo(0,0);
                        } else {
                            document.querySelector(".menu.toggled").scrollTop = 0;
                            self.menuInterface.scrollTop = 0;
                        }
                    }
                }, 0)*/

            }
        }

    }

    componentDidMount(){ let numeroMenu;

        import('body-scroll-lock').then((module) => {
            BodyScrollModule = module;
            this.setState({
                bodyScrollModuleLoaded: true
            });
        });

        //this.animationMenu(this.props.show, true);
        //animations.setOnEnter(this.ref);

        if(this.checkWindowWidth()){
            if(this.props.data.numeros)

                numeroMenu =
                    <NumeroMenu
                        match={ this.props.match }
                        toggleMenu={ this.props.toggleMenu }
                        numeros={ this.props.data.numeros }
                    />
        }

        this.setState({
            show: this.props.show,
            numeroMenu
        });
    }

    getCategories(categories) {

        if(categories) {
            const categoriesArr = categories.slice(0);
            categoriesArr.sort((a, b) => {
                return (a.order - b.order)
            });

            return categoriesArr.slice(0);
        } else {
            return null;
        }
    }

    getArticles(articles, category) {
        return articles.filter((article) => article.category._id === category._id);
    }

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.history.push(link);
    };

    checkWindowWidth() {
        return window.innerWidth <= 425
    };

    getTopValueView() {
        if(typeof window === "undefined") return "0px";
        if(!document.querySelector("header.header")) return "0px";
        let header = document.querySelector("header.header").getBoundingClientRect().height;
        return header + "px";
    }

    setRefListing = (ref) => {
        this.menuInterface = ref;
    };

    render(){ let that = this;

        if(typeof window === "undefined") return null;

        const classes = classNames({
            'toggled': this.props.menu,
            'menu'   : true
        });

        const classesArticlesTest = classNames({
            'open': this.state.open,
            'articles-test'   : true
        });

        let style = {
            marginTop: this.getTopValueView(),
        };

        if(typeof this.props.numero === "undefined") return null;

        return (
            <SettingsContext.Consumer>
                {({ fonts, contact }) => (
                    <div className={ classes } style={ style } ref={(ref) => this.menuInterfaceMobile = ref }>
                        <AnimBackground2 animation={ this.state.animBackground } />

                        <div className="menu-inner">
                            {this.state.numeroMenu}
                            <div className={ classesArticlesTest }>
                                <ul>
                                    {that.getCategories(this.props.numero.categories).map((category, index) => {
                                        const articles = that.getArticles(this.props.numero.articles, category);

                                        return(<ChapitreMenu
                                            category={ category }
                                            logo={ this.props.logo }
                                            articles={ articles }
                                            menuInner={ this.props.menuInner }
                                            setRefListing={ this.setRefListing }
                                            history={ this.props.history }
                                            openCategory={ this.props.menuInner.category }
                                            setMenuInner={ this.props.setMenuInner }
                                            toggleMenuInner={ this.props.toggleMenuInner }
                                            user={ this.props.user }
                                            open={ this.props.menuInner.show }
                                            openToggle={ this.openToggle }
                                            fonts={ fonts }
                                            toggleMenu={ this.props.toggleMenu }
                                            numero={ this.props.numero }
                                            key={ index }
                                        />);

                                    })}
                                </ul>
                            </div>

                        </div>

                        <FooterMenu fonts={ fonts } toggleMenu={ this.props.toggleMenu }
                                    openRGPDPopup={ this.props.openRGPDPopup }
                                    history={ this.props.history } setMenuNumero={ this.props.setMenuNumero }/>
                    </div>
                )}
            </SettingsContext.Consumer>
        )
    }
}

export default MenuView;


const AbsoluteMenuInner = ({ children, toggleOpen, open, template, category, openCategory }) => {

    //alert(open);

    if(open){
        if(category._id !== openCategory._id){
            return null;
        }

        let heightHeader = document.querySelector("header.header").offsetHeight;
        let heightFooterMenu = document.querySelector(".footer-menu").offsetHeight;

        let styles = {
            top: heightHeader + "px",
            height: "calc(100vh - " + (heightFooterMenu + heightHeader) + "px)",
        };

        return createPortal(
            <div className='absoluteMenuInner cci' style={ styles }>
                {children}
            </div>,
            document.getElementById("absolute-menu-inner"))
    } else {
        return null;
    }
};


export class FooterMenu extends Component {

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.setMenuNumero(false);
        this.props.history.push(link);
    };

    render() {
        return(
            <div className="footer-menu">
                <div className="inner-footer-menu">
                    <ul style={ this.props.fonts.family1 }>
                        <li>
                            <Link to={"/credits"}
                                  onClick={(e) => this.openLink("/credits", e) }>
                                Crédits
                            </Link>
                        </li>
                        <li>
                            <Link to={"/mentions-legales"}
                                  onClick={(e) => this.openLink("/mentions-legales", e) }>
                                Mentions légales
                            </Link>
                        </li>
                        <li>
                            <Link to={"/contact"}
                                  onClick={(e) => this.openLink("/contact", e) }>
                                Contact
                            </Link>
                        </li>
                        <li>
                            <div onClick={ this.props.openRGPDPopup }>
                                Gestion des cookies
                            </div>
                        </li>
                    </ul>
                    <div className="credits" style={ this.props.fonts.family4 }>
                        <span>Tous droits réservés CCI Île-de-France</span>
                        <span>Propulsé par <a href="https://www.ridmi.io" target="_blank">Ridmi</a></span>
                    </div>
                </div>
            </div>
        )
    }
}
