import React, { Component } from 'react';
import Header from "../../Numero/components/Header";
import LateralBar from "../../Numero/components/LateralBar";
import Menu from "../../Menu/Menu";
import { GET_PAGE } from "../../../../../Queries";
import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import "../style/Page.scss";
import { Content } from "../../Article/components/Content";
import Metas from "../../Metas/Metas";
import { ContactFormView } from "./ContactFormView";
import MenuNumero from "../../MenuNumero/MenuNumero";
import Search from "../../Search/Search";

export default class PageView extends Component {

    componentDidMount() {
        if(typeof window.scrollTo !== "undefined"){
            window.scrollTo(0,0);
        }
    }

    render() {
        return(
            <div className="numero">
                <LateralBar toggleMenu={ this.props.toggleMenu } isPage={ true } menuShow={ this.props.menuShow } />
                <div className="main" data-text="true">
                    <Header match={ this.props.match } isPage={ true } toggleMenu={ this.props.toggleMenu }/>
                    {!this.props.menuShow && !this.props.menuNumeroActive && !this.props.search &&
                        <div className="page-outer">
                            <PageInner slug={this.props.slug} match={this.props.match} client={this.props.client}/>
                        </div>
                    }
                    <Menu show={ this.props.menuShow } toggleMenu={ this.props.toggleMenu }/>
                    <MenuNumero toggleMenu={ this.props.toggleMenu } />
                    {typeof window !== "undefined" &&
                        <Search toggleMenu={ this.props.toggleMenu } />
                    }
                </div>
            </div>
        )
    }

}

class PageInner extends Component {


    render() {

        return(
            <Query query={ GET_PAGE } variables={{
                magazine_slug: this.context.magazine.slug,
                slug: this.props.slug
            }}>
                {({loading, error, data, updateQuery, client, networkStatus}) => {

                    if(loading) return null;
                    let page = data.getPage;

                    return(
                        <SettingsContext.Consumer>
                            {({ fonts }) => (
                                <div className={['page-inner' + ' ' + page.slug]}>
                                    <Metas article={ page } magazine={ this.context.magazine } isPage={ true }/>

                                    { this.props.slug === "contact" &&
                                        <ContactFormView
                                            page={ page }
                                            match={ this.props.match }
                                            fonts={ fonts }
                                            client={ this.props.client}
                                            magazine_slug={ this.context.magazine.slug }
                                        />
                                    }

                                    {this.props.slug !== "contact" &&
                                        <div>
                                            <div data-text="true" className="title-page">
                                                <h1 style={ fonts.family1 }>{ page.title }</h1>
                                            </div>
                                            <Content
                                                article={ page }
                                                match={ this.props.match }
                                                isPage={ true }
                                            />
                                        </div>
                                    }
                                </div>
                            )}
                        </SettingsContext.Consumer>
                    )

                }}
            </Query>
        )

    }

}


PageInner.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};
