import * as React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Numero from "./Numero/Numero";
import { withApollo } from "react-apollo";
import { Route, Switch } from "react-router";
import Root from "./Root";

import "./theme.scss";
import "../../icofonts/icofonts.scss";
import "./Icofont/icomoon.scss";
import "./Icofont/original/icomoon.scss";

import { PWA } from "./PWA/PWA";
import RGPD from "./RGPD/RGPD";
import Page from "./Page/Page";
import { GET_CURRENT_NUMERO, GET_MENU, UPDATE_CURRENT_NUMERO } from "../../../Queries";
import { connect } from "react-redux";
import InternetExplorer from "../../InternetExplorer/InternetExplorer";
//import {PDFRidmi} from "../../Functionalities/ExportToPdf/ExportToPdf";
//import ExportToPdf from "../../Functionalities/ExportToPdf/ExportToPdf";
//import PDFViewerView from "./PDFViewer/views/PDFViewerView";
// import PDFViewer from "./PDFViewer/PDFViewer";

export class ComponentExemple extends Component {

    render() {
        return(
            <div className="componentExemple" style={ { backgroundColor: "yellow", height: "100vh"}}>
                <h1>Component Exemple</h1>
            </div>
        )
    }


}

class Index extends Component {


    constructor(props) {
        super(props);

        this.state = {
            menuShow: false,
        }
    }

    toggleMenu = (toggle = null, numero = null, pushOnAccueil = true) => { let self = this;

        if (toggle === false) {
            this.setState(() => {
                return {
                    menuShow: false
                }
            });

            this.props.client.writeQuery({
                query: GET_MENU,
                data: {
                    getMenu: false,
                }
            });

            this.props.toggleMenuOff();
            this.props.toggleSearchOff();

        } else {
            this.setState((prevState) => {

                this.props.client.writeQuery({
                    query: GET_MENU,
                    data: {
                        getMenu: !prevState.menuShow,
                    }
                });

                return {
                    menuShow: !prevState.menuShow
                }
            });

            this.props.toggleMenu();
            this.props.toggleSearchOff();
        }

        if(numero) {

            const lastNumero = this.props.client.readQuery({ query: GET_CURRENT_NUMERO });

            if(lastNumero.currentNumero._id === numero._id) {
                return null;
            }

            let numeroObjInData = this.props.datas.numeros.find((numeroData) => numeroData._id === numero._id);

            if(numeroObjInData){
                numero = numeroObjInData;
            }

            this.props.client.writeQuery({
                query: UPDATE_CURRENT_NUMERO,
                data: {
                    currentNumero : numero
                }
            });

            this.props.client.writeQuery({
                query: GET_CURRENT_NUMERO,
                data: {
                    currentNumero : numero
                }
            });

            if(pushOnAccueil && !numeroObjInData) {
                this.props.history.push("/" + numero.slug);
            }

            this.props.changeNumeroOn();
            self.props.toggleSearchOff();

            setTimeout(function() {
                self.props.toggleLoadingOff();
            }, 1000)
        }

    };

    render() {
        return(
            <div className="cci">
                <Switch>
                    {/*<Route path="/imprimer/article/:id" render={ props => (
                        <PDFViewer
                            {...props} toggleMenu={ this.toggleMenu }
                            menuShow={ this.state.menuShow }
                        />
                    )}/>
                    <Route path="/imprimer" render={ props => (
                        <PDFViewer {...props} toggleMenu={ this.toggleMenu }
                                   menuShow={ this.state.menuShow }
                        />
                    )}/>*/}
                    <Route exact path="/credits" render={ props => (
                        <Page {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route exact path="/mentions-legales" render={ props => (
                        <Page {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route exact path="/contact" render={ props => (
                        <Page {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route exact path="/" render={ props => (
                        <Root {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route path="/index.html" render={ props => (
                        <Root {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route path="/:id" render={ props => (
                        <Numero {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                </Switch>
                <PWA />
                <RGPD toggleMenu={ this.toggleMenu }/>
                <InternetExplorer />
            </div>
        )
    }

}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLoadingOff: () => {
            dispatch({
                type: "LOADING_VIEW",
                loading: false
            })
        },
        changeNumeroOn: () => {
            dispatch({
                type: 'CHANGE_NUMERO_ON'
            });
        },
        toggleSearchOff: () => {
            dispatch({
                type: 'TOGGLE_SEARCH_VALUE',
                value: false
            });
        },
        toggleMenuOff: () => {
            dispatch({
                type: 'TOGGLE_MENU_VALUE',
                value: false
            });
        },
        toggleMenuOn: () => {
            dispatch({
                type: 'TOGGLE_MENU_VALUE',
                value: true
            });
        },
        toggleMenu: () => {
            dispatch({
                type: 'TOGGLE_MENU',
                value: true
            });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo( Index )));
