import * as React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { GET_ALL } from "../../../Queries";
import { Query } from "react-apollo";
import Numero from "./Numero/Numero";
import { connect } from "react-redux";

export class Root extends Component {

    constructor(props) {
        super(props);
        this.props.setMenuNumero(false);
    }

    render() { let that = this;

        return(
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }}>
                {({loading, error, data, updateQuery, client, networkStatus}) => {
                    return (<Numero numero={ data.datas.numeros[0] }
                                    menuShow={ this.props.menuShow }
                                    toggleMenu={ this.props.toggleMenu }
                    />);
                }}
            </Query>
        )

    }


}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMenuNumero: (value) => {
            dispatch({
                type: "SET_MENU_NUMERO",
                value
            })
        }
    };
};

Root.contextTypes = {
    magazine: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
