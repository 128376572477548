import React, { PureComponent } from 'react';
import { Route, Switch } from "react-router";

import Article from "../../../Article/Article";
import Couverture from "../../../Couverture/Couverture";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import ContentChapitre from "../../../Content/ContentChapitre";
import ContentArticle from "../../../Content/ContentArticle";
import ContentPlayer from "../../../Content/ContentPlayer";

class MainView extends PureComponent {

    render() {
        let { match, isPreview, previewLoading, loadingView } = this.props;

        return (
            <main>

                <Switch>
                    <Route path={`${match.url}/:chapitre/:slug`} render={ (props) => {
                        return <ContentArticle {...props} toggleMenu={ this.props.toggleMenu } />
                    }} />

                    <Route path={`${match.url}/:chapitre`} render={ (props) => {
                        return <ContentChapitre {...props} toggleMenu={ this.props.toggleMenu } />
                    }} />

                    <Route path={`${match.url}`} component={ Couverture } />
                </Switch>

                <div className="timeline-main">
                    <Route path={[ `${match.url}/:chapitre/:slug`, `${match.url}/:chapitre` ]} render={ (props) => {
                        return <ContentPlayer {...props} toggleMenu={ this.props.toggleMenu } />
                    }} />
                </div>

                {loadingView && <div className="loading-main">
                    <div className="logo" />
                </div>}

            </main>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        loadingView: state.loadingView.loadingMain
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(MainView)));
