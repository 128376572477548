import * as React from "react";
import { withRouter } from "react-router";
import {GET_CATEGORY, GET_CURRENT_NUMERO} from "../../../../Queries";
import { Query } from "react-apollo";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Component } from "react";
import Content from "./Content";
import { ArticleContextScroll } from "./ContentArticle";
import { ScrollContext } from "../../../Scroll/Scroll";

class ContentChapitre extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display: false,
            ref: null,
            listenScroll: true,
        };
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.match.params.slug !== this.props.match.params.slug || !prevState.listenScroll){
            this.resetListenScroll();
        }
    }

    componentDidMount() {
        if(this.ref){
            //animations.ArticleEnter(this.ref);
        }
    }

    componentWillUnmount() {
        if(this.ref) {
            //animations.ArticleLeave(this.ref);
        }
    }

    findArticlesToChapitre(articles, numero) {
        if(!articles) {
            this.props.history.push("/"); return null;
        }
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    getCategoryWithQuery(categories, numero) {

        if(!categories) {
            this.props.history.push("/"); return null;
        }

        let category = categories.filter((category) => category.slug === this.props.match.params.chapitre)[0];

        if(!category) {
            return null;
        }

        return category;

    }

    blockListenScroll = () => {
        this.setState({
            listenScroll: false,
        })
    };

    resetListenScroll = () => {
        this.setState({
            listenScroll: true,
        })
    };

    articleIsRead = (user, article) => {
        if(user.articles) {
            if(!article) return null;
            return user.articles.some((articleObj) => {
                return (articleObj._id.split("_")[0] === article._id.split("_")[0] && articleObj.readed);
            });
        }
    };

    render() {

        if(!this.props.match.params.chapitre) return null;

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(error) return null;
                    if(loading) return null;

                    let currentNumero = data.currentNumero;
                    let articles = currentNumero.articles;
                    let categories = currentNumero.categories;
                    let articles_chapitre = this.findArticlesToChapitre(articles, currentNumero);
                    let category = this.getCategoryWithQuery(categories, currentNumero);

                    if(!category) return null;

                    return(
                        <Query query={ GET_CATEGORY }
                               variables={{ magazine_slug: this.context.magazine.slug, category_id: category._id }}
                               fetchPolicy="cache-first">
                            {({ loading, error, data, networkStatus }) => {

                                if(loading) return null;

                                if(data.getCategory)
                                    return (<div ref={ this.setReference }>
                                        <Content
                                            match={ this.props.match }
                                            display={ this.state.display }
                                            toggleMenu={ this.props.toggleMenu }
                                            magazine={ this.context.magazine }
                                            articles={ articles_chapitre }
                                            articleDetail={ data.getCategory }
                                            article={ data.getCategory }
                                            allArticles={ articles }
                                            type="chapitre"
                                            categories={ currentNumero.categories }
                                            numero={ currentNumero }
                                        />
                                        <ScrollContext.Consumer>
                                            {({ changeScrollWidth, scrollWidth, resetScrollWidth }) => (
                                                <ArticleContextScroll
                                                    scrollWidth={ scrollWidth }
                                                    resetScrollWidth={ resetScrollWidth }
                                                    user={ this.props.user }
                                                    fixTimelineBar={ this.props.fixTimelineBar }
                                                    fixTimelineBarValue={ this.props.fixTimelineBarValue }
                                                    unfixTimelineBar={ this.props.unfixTimelineBar }
                                                    blockListenScroll={ this.blockListenScroll }
                                                    resetListenScroll={ this.resetListenScroll }
                                                    currentNumero={ currentNumero }
                                                    setArticleReaded={ this.props.setArticleReaded }
                                                    articleIsRead={ this.articleIsRead }
                                                    article={ data.getCategory }
                                                    listenScroll={ this.state.listenScroll }
                                                />
                                            )}
                                        </ScrollContext.Consumer>
                                    </div>);

                                return "Article network status";

                            }}
                        </Query>
                    );

                }}
            </Query>
        )
    }
}

ContentChapitre.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        render: state.renders.article,
        user: state.user,
        fixTimelineBarValue: state.scrolled.fixTimelineBar
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        renderArticle: () => {
            dispatch({
                type: 'RENDER_ARTICLE'
            });
        },
        setArticleReaded: (article, numero) => {
            dispatch({
                type: 'USER_SET_ARTICLE_READ',
                article,
                typeData: "article",
                numero
            });
        },
        fixTimelineBar: () => {
            dispatch({
                type: 'FIX_TIMELINE_BAR',
            });
        },
        unfixTimelineBar: () => {
            dispatch({
                type: 'UNFIX_TIMELINE_BAR',
            });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ContentChapitre)));
