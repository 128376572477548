import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuView from "./views/MenuView";
import { GET_ALL, GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SettingsContext } from "../../../Magazine/MagazineSettings";

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <Query query={ GET_ALL } variables={
                {
                    magazine_slug: this.context.magazine.slug,
                    offset: 0
                }
            } fetchPolicy="cache-first">
                {({ loading, error, data, fetchMore }) => {
                    let datas = data.datas;

                    return(
                        <SettingsContext.Consumer>
                            {({ logo }) => {
                                return (
                                    <Query query={GET_CURRENT_NUMERO}>
                                        {({loading, error, data, client}) => {
                                            return (
                                                <MenuView show={ this.props.show }
                                                          numero={ data.currentNumero }
                                                          user={ this.props.user }
                                                          match={ this.props.match }
                                                          toggleMenuInner={ this.props.toggleMenuInner }
                                                          setMenuNumero={ this.props.setMenuNumero }
                                                          setMenuInner={ this.props.setMenuInner }
                                                          history={ this.props.history }
                                                          toggleMenu={ this.props.toggleMenu }
                                                          menuInner={ this.props.menuInner }
                                                          menu={ this.props.menu }
                                                          logo={ logo }
                                                          data={ datas }
                                                          captureContactClick={ this.props.captureContactClick }
                                                          openRGPDPopup={ this.props.openRGPDPopup }
                                                />
                                            )
                                        }}
                                    </Query>
                                )
                            }}
                        </SettingsContext.Consumer>
                    )

                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {

    return {
        magazine: state.magazine,
        user: state.user,
        menu: state.menu.menuShow,
        menuInner: {
            show: state.menu.menuInnerShow,
            category: state.menu.menuInnerCategory
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleMenuInner: () => {
            dispatch({
                type: "TOGGLE_MENU_INNER",
            })
        },
        setMenuNumero: (value) => {
            dispatch({
                type: "SET_MENU_NUMERO",
                value
            })
        },
        setMenuInner: (value, category) => {
            dispatch({
                type: "TOGGLE_MENU_INNER_VALUE",
                value,
                category
            })
        },
        openRGPDPopup: () => {
            dispatch({
                type: "OPEN_WINDOW_RGPD",
                value: true
            })
        },
        captureContactClick: () => {
            dispatch({
                type: "CLICK_CONTACT"
            })
        },
    };
};

Menu.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));

